import React from "react";
import MDEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import "@mdxeditor/editor/style.css";
import styles from "./MarkDownEditor.css";

const MarkDownEditor = (props) => {
    const { setDescription, description } = props;

    return (
        <div>
            <MDEditor height={900} value={description} onChange={setDescription} />
            <div className={styles.codeBlockExample}>
                <pre><code></code></pre>
            </div>
        </div>
    );
};


export default MarkDownEditor;
