import React, { useState } from "react";
import "./AddBlogs.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TurndownService from "turndown";
import { useEffect } from "react";

import {
    postQueryForm,
    uploadBlogImageToCloud,
    deleteBlogImageFromCloud,
    updateBlogs,
} from "./Adminclient";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/Snackbar";
import CloseIcon from "@mui/material/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@mui/material/Button";
import AddAuthorDropDown from "./AddAuthorDropDown";
import AddBlogTagsDropDown from "./AddBlogTagsDropDown";
import upload from "../../resources/images/upload.svg";
import dustbin from "../../resources/images/dustbin.svg";
import iButton from "../../resources/images/i.svg";
import MarkDownEditor from "./MarkDownEditor";
import { useGlobalContext, getIsUserAdmin } from "../../context/GlobalContext";
import { useParams } from "react-router-dom";

const AddBlogs = (props) => {
    const { blog_id } = useParams();
    const [blogId, setBlogId] = useState("");
    const [title, setTitle] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [description, setDescription] = useState("");
    const [readTime, setReadTime] = useState("");
    const [startDate, setStartDate] = useState(0);
    const [largeImage, setLargeImage] = useState("");
    const [largeImageLoader, setLargeImageLoader] = useState(false);
    const [mediumImage, setMediumImage] = useState("");
    const [mediumImageLoader, setMediumImageLoader] = useState(false);
    const [smallImage, setSmallImage] = useState("");
    //const [blogContentImage, setBlogContentImage] = useState("");
    const [smallImageLoader, setSmallImageLoader] = useState(false);
    //const [blogContentImageLoader, setBlogContentImageLoader] = useState(false);
    const [urlPath, setUrlPath] = useState("");
    const [tagName, setTagName] = React.useState([]);
    const [SnackbarOpen, setSnackBarOpen] = useState(false);
    const [SnackbarClose, setSnackBarClose] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ytLink, setYtLink] = useState("");
    const [edit, setEdit] = useState(false);

    const { isAdmin, setIsAdmin } = useGlobalContext();

    useEffect(() => {
        if (props && props.blogDetails && props.blogDetails.content) {
            setTitle(props.blogDetails.title);
            setUrlPath(props.blogDetails.url);
            const turndownService = new TurndownService();
            setDescription(turndownService.turndown(props.blogDetails.content));
            setReadTime(props.blogDetails.read_time_min);
            setLargeImage(props.blogDetails.banner_image_url);
            setMediumImage(props.blogDetails.preview_image_url);
            setSmallImage(props.blogDetails.most_viewed_blog_image_url);
            setAuthorName(props.blogDetails.author);
            setYtLink(props.blogDetails.youtube_link);
            setTagName(props.tagsList);
            setBlogId(props.blog_id);
        }
    }, [props]);

    useEffect(() => {
        setTitle("");
        setUrlPath("");
        setDescription("");
        setReadTime("");
        setLargeImage("");
        setMediumImage("");
        setSmallImage("");
        setAuthorName("");
        setStartDate("");
        setTagName([]);
        setYtLink("");
    }, [SnackbarOpen]);

    const selectDate = (date) => {
        setStartDate(date);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        var showdown = require("showdown");
        var converter = new showdown.Converter(),
            html = converter.makeHtml(description);
        let data = {
            title: title,
            urlPath: urlPath,
            authorName: authorName,
            description: html,
            readTime: readTime,
            startDate: startDate,
            updatedDate: startDate,
            largeImage: largeImage,
            mediumImage: mediumImage,
            smallImage: smallImage,
            tagName: tagName,
            youtubeLink: ytLink,
        };

        if (props.blog_id) {
            updateBlogs(data, blogId, setSnackBarOpen, setSnackBarClose);
        } else {
            postQueryForm(data, setSnackBarOpen, setSnackBarClose);
        }
    };

    const getDateAndTime = () => {
        return (
            <div className="get-time-date-wrapper">
                <div>
                    <p className="form-input-title">Date</p>
                    <DatePicker
                        selected={startDate}
                        onSelect={selectDate}
                        placeholderText="MM/DD/YYYY"
                        dateFormat="dd MMM yyyy"
                        portalId="pauseDatePicker-portal"
                        maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                    />
                </div>
                <div>
                    <p className="form-input-title">Read Time (in min) </p>
                    <input
                        type="number"
                        value={readTime}
                        onChange={(e) => setReadTime(e.target.value)}
                    />
                </div>
            </div>
        );
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleErrorSnackbarOpen = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarClose(false);
    };

    const handleEditToggle = () => {
        setEdit(!edit);
    };

    const getAddBlogDescription = () => {
        return (
            <div className="w-[60%] p-[2vw]">
                <label>
                    <p className="form-input-title">Blog Title</p>
                    <input
                        placeholder="Name"
                        type="text"
                        value={title}
                        style={{ width: "100%" }}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </label>
                <label>
                    <p className="form-input-title">Add Blog</p>
                    <div>
                        <MarkDownEditor setDescription={setDescription} description={description} />
                    </div>
                </label>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={SnackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleErrorSnackbarClose}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleErrorSnackbarClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    message="Blog posted successfully"
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={SnackbarClose}
                    autoHideDuration={4000}
                    onClose={handleErrorSnackbarOpen}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleErrorSnackbarOpen}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    message="Oops! Something Went Wrong"
                />
            </div>
        );
    };

    const handleLargeImageSelect = (event) => {
        const formData = new FormData();
        formData.append("blog-image", event.target.files[0]);
        formData.append("blog-image-type", "banner-image");
        formData.append("blog-id", blog_id);
        setLargeImageLoader(true);
        uploadBlogImageToCloud(formData, setLargeImage, setLargeImageLoader, setSnackBarClose);
    };

    const handleMediumImageSelect = (event) => {
        const formData = new FormData();
        formData.append("blog-image", event.target.files[0]);
        formData.append("blog-image-type", "preview-image");
        formData.append("blog-id", blog_id);
        setMediumImageLoader(true);
        uploadBlogImageToCloud(formData, setMediumImage, setMediumImageLoader, setSnackBarClose);
    };

    const handleSmallImageSelect = (event) => {
        const formData = new FormData();
        formData.append("blog-image", event.target.files[0]);
        formData.append("blog-image-type", "most-viewed-image");
        formData.append("blog-id", blog_id);
        setSmallImageLoader(true);
        uploadBlogImageToCloud(formData, setSmallImage, setSmallImageLoader, setSnackBarClose);
    };

    // const handleBlogContentImageSelect = (event) => {
    //     const formData = new FormData();
    //     formData.append("blog-image", event.target.files[0]);
    //     formData.append("blog-image-type", "blog-content-image");
    //     formData.append("blog-id", blog_id);
    //     setBlogContentImageLoader(true);
    //     uploadBlogImageToCloud(
    //         formData,
    //         setBlogContentImage,
    //         setBlogContentImageLoader,
    //         setSnackBarClose
    //     );
    // };
    const getLargeImage = () => {
        return (
            <div className="img-upload-wrapper">
                {largeImageLoader ? <div>Loading...</div> : ""}
                <div className="blog-image-upload-div">
                    <label htmlFor="large-image-upload" className="blog-img-label">
                        <img
                            src={upload}
                            alt=""
                            style={{
                                marginRight: "0.7vw",
                                marginLeft: "0.7vw",
                            }}
                        />
                        Choose Banner Img (1440*500)
                        <input
                            type="file"
                            onChange={handleLargeImageSelect}
                            id="large-image-upload"
                            hidden
                        />
                    </label>
                    {largeImage ? (
                        <button
                            onClick={() =>
                                deleteBlogImageFromCloud(
                                    largeImage,
                                    "banner-image",
                                    setLargeImage,
                                    setLargeImageLoader,
                                    setSnackBarClose
                                )
                            }
                            className="blog-image-delete-btn"
                        >
                            <img src={dustbin} />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {largeImage ? (
                    <a href={largeImage} passHref target="_blank" className="blog-img-preview-txt" rel="noreferrer">
                        View Image
                    </a>
                ) : (
                    ""
                )}
            </div>
        );
    };
    const getMediumImage = () => {
        return (
            <div className="img-upload-wrapper">
                {mediumImageLoader ? <div>Loading...</div> : ""}
                <div className="blog-image-upload-div">
                    <label htmlFor="medium-image-upload" className="blog-img-label">
                        <img
                            src={upload}
                            alt=""
                            style={{
                                marginRight: "0.7vw",
                                marginLeft: "0.7vw",
                            }}
                        />
                        Choose Preview Img (777*340)
                        <input
                            type="file"
                            onChange={handleMediumImageSelect}
                            id="medium-image-upload"
                            hidden
                        />
                    </label>
                    {mediumImage ? (
                        <button
                            onClick={() =>
                                deleteBlogImageFromCloud(
                                    mediumImage,
                                    "preview-image",
                                    setMediumImage,
                                    setMediumImageLoader,
                                    setSnackBarClose
                                )
                            }
                            className="blog-image-delete-btn"
                        >
                            <img src={dustbin} />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {mediumImage ? (
                    <a href={mediumImage} passHref target="_blank" className="blog-img-preview-txt" rel="noreferrer">
                        View Image
                    </a>
                ) : (
                    ""
                )}
            </div>
        );
    };

    const getSmallImage = () => {
        return (
            <div className="img-upload-wrapper">
                {smallImageLoader ? <div>Loading...</div> : ""}
                <div className="blog-image-upload-div">
                    <label htmlFor="small-image-upload" className="blog-img-label">
                        <img
                            src={upload}
                            alt=""
                            style={{
                                marginRight: "0.7vw",
                                marginLeft: "0.7vw",
                            }}
                        />
                        Choose Card Img (373*290)
                        <input
                            type="file"
                            onChange={handleSmallImageSelect}
                            id="small-image-upload"
                            hidden
                        />
                    </label>
                    {smallImage ? (
                        <button
                            onClick={() =>
                                deleteBlogImageFromCloud(
                                    smallImage,
                                    "most-viewed-image",
                                    setSmallImage,
                                    setSmallImageLoader,
                                    setSnackBarClose
                                )
                            }
                            className="blog-image-delete-btn"
                        >
                            <img src={dustbin} />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {smallImage ? (
                    <a href={smallImage} passHref target="_blank" className="blog-img-preview-txt" rel="noreferrer">
                        View Image
                    </a>
                ) : (
                    ""
                )}
            </div>
        );
    };
    //Not using 4th image
    // const getBlogContentImage = () => {
    //     return (
    //         <div className="img-upload-wrapper">
    //             {blogContentImageLoader ? <div>Loading...</div> : ""}
    //             <div className="blog-image-upload-div">
    //                 <label htmlFor="blog-content-image-upload" className="blog-img-label">
    //                     <img
    //                         src={upload}
    //                         alt=""
    //                         style={{
    //                             marginRight: "0.7vw",
    //                             marginLeft: "0.7vw",
    //                         }}
    //                     />
    //                     Choose Blog Content Img (373*290)
    //                     <input
    //                         type="file"
    //                         onChange={handleBlogContentImageSelect}
    //                         id="blog-content-image-upload"
    //                         hidden
    //                     />
    //                 </label>
    //             </div>
    //             {blogContentImage ? (
    //                 <a
    //                     href={blogContentImage}
    //                     passHref
    //                     target="_blank"
    //                     className="blog-img-preview-txt" rel="noreferrer"
    //                 >
    //                     View Image And paste the url into markdown editor
    //                 </a>
    //             ) : (
    //                 ""
    //             )}
    //         </div>
    //     );
    // };
    const getImageUploader = () => {
        return (
            <>
                {getLargeImage()}
                {getMediumImage()}
                {getSmallImage()}
            </>
        );
    };

    const getAdditionalDetailsSection = () => {
        return (
            <div className="add-blog-additional-info">
                <p className="add-blog-additional-info-title form-input-title">
                    Additional Details
                </p>
                <div style={{ paddingLeft: "2.5vw" }}>
                    <label>
                        <div className="url-path">
                            <p className="form-input-title">Blog URL Path</p>
                            <Tooltip
                                title={
                                    <div className="overview-cards-hover-info">
                                        "Words should be seperated by hyphen(-) and maximum words
                                        should be 6 to 7 for better SEO"
                                    </div>
                                }
                                placement="top-start"
                            >
                                <Button>
                                    <img className={"image-iIcon"} src={iButton} />
                                </Button>
                            </Tooltip>
                        </div>
                        <input
                            placeholder="URL Path"
                            image={iButton}
                            type="text"
                            value={urlPath}
                            onChange={(e) => setUrlPath(e.target.value)}
                        />
                    </label>
                    <label>
                        <p className="form-input-title">Author Name</p>
                        <div>
                            <AddAuthorDropDown
                                setAuthorName={setAuthorName}
                                authorName={authorName}
                            />
                        </div>
                    </label>
                    {getDateAndTime()}
                    <div>
                        <p className="form-input-title">Add Tag</p>
                        <div>
                            <AddBlogTagsDropDown setTagName={setTagName} tagName={tagName} />
                        </div>
                    </div>

                    <div>
                        <p className="form-input-title">Add YouTube link</p>
                        <input
                            placeholder="YouTube link"
                            type="text"
                            value={ytLink}
                            style={{ width: "100%" }}
                            onChange={(e) => setYtLink(e.target.value)}
                        />
                    </div>
                    {getImageUploader()}
                </div>
                <div className="publish-blog-button-wrapper">
                    <button className="publish-blog-button" onClick={handleSubmit}>
                        Publish
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div>
            {isAdmin ? (
                <div>
                    <div className="add-blog-form-wrapper">
                        {getAddBlogDescription()}
                        {getAdditionalDetailsSection()}
                    </div>
                </div>
            ) : (
                <div className="not-an-admin-section">Your Are Not Admin to Add New Blog</div>
            )}
        </div>
    );
};

export default AddBlogs;